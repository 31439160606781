<template>
  <v-dialog
    :value="mostrar"
    content-class="dialog-pessoa"
    @click:outside="$emit('fechar')"
  >
    <v-card>
      <v-toolbar
        color="grey lighten-3"
        flat
        dense
      >
        <v-toolbar-title>
          Pessoa
        </v-toolbar-title>

        <template v-slot:extension>
          <v-tabs v-model="tab">
            <v-tabs-slider color="primary" />
            <v-tab>
              Dados
            </v-tab>
            <v-tab :disabled="!pessoaId">
              Endereços
            </v-tab>
            <v-tab :disabled="!pessoaId">
              E-mails
            </v-tab>
            <v-tab :disabled="!pessoaId">
              Telefones
            </v-tab>
          </v-tabs>
        </template>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            text
            small
            @click="$emit('fechar')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-tabs-items v-model="tab">
        <v-tab-item :key="'dados'">
          <pessoa-dados
            :pessoa-id="pessoaId"
            :limpar-ao-salvar="false"
            @salvar="$emit('salvar:dados', $event)"
          />
        </v-tab-item>
        <v-tab-item>
          <pessoa-enderecos
            :pessoa-id="pessoaId"
          />
        </v-tab-item>
        <v-tab-item>
          <pessoa-emails
            :pessoa-id="pessoaId"
          />
        </v-tab-item>
        <v-tab-item>
          <pessoa-telefones
            :pessoa-id="pessoaId"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>

  export default {

    components: {
      PessoaDados: () => import('@/components/dialog/pessoa/DialogPessoaDados'),
      PessoaEmails: () => import('@/components/dialog/pessoa/DialogPessoaEmails'),
      PessoaTelefones: () => import('@/components/dialog/pessoa/DialogPessoaTelefones'),
      PessoaEnderecos: () => import('@/components/dialog/pessoa/DialogPessoaEnderecos'),
    },

    props: {
      mostrar: {
        type: Boolean,
        default: false,
      },
      pessoaId: {
        type: Number,
        default: 0,
      },
    },

    data () {
      return {
        tab: 0,
      }
    },

    methods: {

    },

  }
</script>

<style lang="css">
  .dialog-pessoa {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    .dialog-pessoa {
      width: 75% !important;
    }
  }
</style>
